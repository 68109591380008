import {
  BANKING_AUTH_TOKEN, BANKING_USER_NAME,
  domain,
  FREESPIN_AUTH_TOKEN, FREESPIN_USER_NAME, FREESPIN_USER_EMAIL,
  NEW_PASSWORD,
} from './config';
import {
  clearStorageItem,
  getStorageItem,
  setStorageItem,
} from '../system';

// FREESPINS TOKEN
export const setToken = (token) => {
  setStorageItem(FREESPIN_AUTH_TOKEN, token);
};
export const getToken = () => getStorageItem(FREESPIN_AUTH_TOKEN);
export const isToken = () => (getToken() && getToken().length > 0);
export const clearToken = () => {
  clearStorageItem(FREESPIN_AUTH_TOKEN);
};
export const getBearer = () => (`Bearer ${getToken()}`);
// FREESPINS USER
export const setUserName = (username) => {
  setStorageItem(FREESPIN_USER_NAME, username);
};
export const getUserName = () => getStorageItem(FREESPIN_USER_NAME);
export const isUserName = () => (getUserName() && getUserName().length > 0);
export const clearUserName = () => {
  clearStorageItem(FREESPIN_USER_NAME);
};
// FREESPINS USER EMAIL
export const setUserEmail = (email) => {
  setStorageItem(FREESPIN_USER_EMAIL, email);
};
export const getUserEmail = () => getStorageItem(FREESPIN_USER_EMAIL);
export const isUserEmail = () => (getUserEmail() && getUserEmail().length > 0);
export const clearUserEmail = () => {
  clearStorageItem(FREESPIN_USER_EMAIL);
};
// NEW PASSWORD
export const setNewPass = (password) => {
  setStorageItem(NEW_PASSWORD, btoa(password));
};
export const getNewPass = () => atob(getStorageItem(NEW_PASSWORD));
export const clearNewPass = () => clearStorageItem(NEW_PASSWORD);
// BANKING TOKEN
export const setBankingToken = (token) => {
  setStorageItem(BANKING_AUTH_TOKEN, token);
};
export const getBankingToken = () => getStorageItem(BANKING_AUTH_TOKEN);
export const isBankingToken = () => (getToken() && getToken().length > 0);
export const clearBankingToken = () => {
  clearStorageItem(BANKING_AUTH_TOKEN);
};
export const getBankingBearer = () => (`Bearer ${getToken()}`);
// BANKING USER
export const setBankingUserName = (username) => {
  setStorageItem(BANKING_USER_NAME, username);
};
export const getBankingUserName = () => getStorageItem(BANKING_USER_NAME);
export const isBankingUserName = () => (getUserName() && getUserName().length > 0);
export const clearBankingUserName = () => {
  clearStorageItem(BANKING_USER_NAME);
};

export const buildQuery = (params) => {
  if (Object.keys(params).length === 0) return '';
  return `?${Object.entries(params)?.map(([key, value]) => (`${key}=${value}`)).join('&')}`;
};

export const request = async ({ path, params = {}, method = 'GET', body = {}, headers = {} }) => {
  const url = `${domain}${path}${buildQuery(params)}`;

  const options = {
    method,
    headers: {
      ...headers,
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  };
  if (method !== 'GET') {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(url, options);
  return await response.json();
};

export const bearerRequest = async ({ path, params, method, body }) => {
  if (!isToken()) {
    return new Promise((resolve) => resolve({}));
  }
  return await request({
    path,
    params,
    method,
    body,
    headers: {
      Authorization: getBearer(),
    },
  });
};

const baseRequest = (method) => async (url, options, headers = {}, bearer = false) => {
  const req = await fetch(url, {
    ...options,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: bearer ? getBearer() : '',
      ...headers,
    },
    method,
  }).then((response) => response.json());

  return req;
};

export const api = {
  get: baseRequest('GET'),
  post: baseRequest('POST'),
};
