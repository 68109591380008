export default {
  // FORM DICTIONARY
  formSentSuccessfully: 'ส่งแบบฟอร์มสำเร็จแล้ว! ตรวจสอบอีเมลของคุณและเปิดใช้งานบัญชี',
  formSentFailed: 'การส่งแบบฟอร์มล้มเหลว กรุณาลองอีกครั้ง',
  formRequiredField: 'จำเป็นต้องใช้',
  formMin100Chars: 'อย่างน้อย 100 ตัวอักษร',
  formIncorrectPassword: 'รหัสผ่านผิดพลาด',
  formBagRequest: 'คำขอไม่ถูกต้อง',
  formLoginOrPasswordIncorrect: 'ขออภัย ยูสเซอร์เนมและ/หรือรหัสผ่านไม่ถูกต้อง',

  // GAME DICTIONARY
  gameMyAccount: 'บัญชีของฉัน',
  gameWithdrawMoney: 'ถอนเงิน',
  gameMyProfile: 'ประวัติของฉัน',
  gameTerms: 'ข้อกำหนดและเงื่อนไข',
  gameLogOut: 'ออกจากระบบ',
  gameTitleBlueButton: 'เล่นตอนนี้',

  // USER DICTIONARY
  notLoggedInTitle: 'คุณยังไม่ได้เข้าสู่ระบบ.',
  logout: 'ออกจากระบบ',
  currentPassword: 'รหัสผ่านปัจจุบัน',
  newPassword: 'รหัสผ่านใหม่',
  confirmPassword: 'ยืนยันรหัสผ่าน',
  bonuses: 'โบนัส',
  accountSettings: 'การตั้งค่าบัญชี',

  // MENU
  bets_allBets: 'การเดิมพันทั้งหมด',
  bets_alreadyPlayed: 'เล่นแล้ว',
  bets_openBets: 'เปิดการเดิมพัน',
  wb_pendingBonuses: 'โบนัสที่รอดำเนินการ',
  wb_activeBonuses: 'โบนัสที่ใช้งานอยู่',
  as_security: 'ความปลอดภัย',
  winning_bonuses: 'โบนัสที่ชนะ',
  bets: 'เดิมพัน',

  // BANKING
  depositOrTopUp: 'ฝาก',
  withdraw: 'ถอน',
  submitDeposit: 'ส่งเงินฝาก',
  submitWithdraw: 'ส่งถอน',
  qrDescLine1: 'การโอนเงินจากบัญชีธนาคาร จะต้องตรงกันกับชื่อบัญชีที่ระบุไว้ในตอนสมัครสมาชิกเท่านั้น',
  qrDescLine2: 'วิธีฝากเงิน',
  qrStep1: 'ระบุจำนวนเงินที่ต้องการฝาก หลังจากนั้นเลือกบัญชีธนาคารของท่านที่จะใช้ในการฝากเงิน',
  qrStep2: 'กดปุ่ม “ขอรับบัญชีธนาคาร” และรอรับ QR Code สำหรับทำรายการฝากเงิน',
  qrStep3: 'บันทึกรูปภาพ QR Code และทำการฝากเงินที่แอปพลิเคชันในธนาคารของท่าน',
  qrStep4: 'หลังจากทำการโอนเรียบร้อยแล้ว ระบบจะทำรายการฝากอัตโนมัติให้ภายใน 5 นาที โดยที่ไม่ต้องแจ้งฝากเงินกับเจ้าหน้าที่',

  // BETS
  youStillNotHaveBets: 'คุณยังไม่มีการเดิมพันใด ๆ . . .',

  // WINNING
  youStillNotHaveBonuses: 'คุณยังไม่มีโบนัสที่ชนะ . . .',

  // PREDICTIONS
  predictionHomeTitle: 'ทำการทำนายและรับเงินจริง',
  predictionHelpText:
    'วางเดิมพันในนัดเดียวหรือหลายนัดสะสมคะแนน (ทายถูก 3 แต้ม, ทำนายไม่ถูก - 1 แต้ม) และรับโบนัสจริง X บาท',
  noMatchesForBet: 'ไม่มีการเดิมพันที่ตรงกัน . . .',
  timerText: 'การเดิมพันของคุณได้รับการยอมรับแล้วคุณสามารถวางเดิมพันครั้งต่อไปได้ในภายหลัง:',
  betHistoryHere: 'ประวัติการเดิมพันที่นี่',
  betAccepted: 'ยอมรับการเดิมพัน!',
  yourPredictionIsAccepted: 'การคาดการณ์ของคุณได้รับการยอมรับคุณสามารถดูไฟล์',
  leaderboard: 'ลีดเดอร์บอร์ด',
  balanceHere: 'ยอดคงเหลือที่นี่',
  checkYou: 'ตรวจสอบคุณ',

  // OTHER DICTIONARY
  comingSoon: 'เร็ว ๆ นี้',
  deposit: 'ฝาก-ถอน',
  watchVideo: 'ดูวีดีโอ',
  uploadAvatar: 'อัปโหลด Avatar',
  processing: 'การประมวลผล . . .',
  processed: 'การประมวลผล!',
  passwordWasChenged: 'รหัสผ่านของคุณได้รับการอัปเดตเรียบร้อยแล้ว กรุณาใช้รหัสผ่านใหม่สำหรับการเข้าสู่ระบบครั้งต่อไป',
  actionNotValid: 'การดำเนินการไม่ถูกต้อง',
  home: 'บ้าน',
  profile: 'ข้อมูลส่วนตัว',
  copiedToClipboard: 'คัดลอกไปยังคลิปบอร์ดแล้ว!',
  accept: 'ยอมรับ',
  playPredictionGame: 'เล่นเกมทำนาย',
  gameHistory: 'ประวัติเกม',
  makeABet: 'ทำการเดิมพัน',
  loading: 'กำลังโหลด',
  date: 'วันที่',
  prediction: 'คาดการณ์',
  score: 'คะแนน',
  points: 'จุด',
  ok: 'ตกลง',
  user: 'ผู้ใช้',
  total: 'รวม',
  share: 'แบ่งปัน',
  login: 'เข้าสู่ระบบ',
};
