// PREDICTIONS TYPES
export const predictionsError = 'PREDICTIONS@SET_ERROR';
export const predictionsBets = 'PREDICTIONS@BETS';
export const predictionRoundsLoaded = 'PREDICTIONS@FETCH_ROUNDS';
export const predictionLeaderBoardLoaded = 'PREDICTIONS@FETCH_LEADERBOARD';
export const predictionHistoryLoaded = 'PREDICTIONS@FETCH_HISTORY';
export const predictionStartRoundsLoad = 'PREDICTIONS@START_LOAD_ROUNDS';
export const predictionStartHistoryLoad = 'PREDICTIONS@START_LOAD_HISTORY';
export const predictionStartLeaderBoardLoad = 'PREDICTIONS@START_LOAD_LEADER_BOARD';
export const predictionSetCurrentLeagueId = 'PREDICTIONS@SET_CURRENT_LEAGUE_ID';
export const predictionsOnSubmit = 'PREDICTIONS@ON_SUBMIT';
export const removeTeamBet = 'PREDICTIONS@REMOVE_TEAM_BET';
export const togglePredictionsLeague = 'PREDICTIONS@TOGGLE_LEAGUE';
export const predictionsSetDefaultValues = 'PREDICTIONS@SET_DEFAULT_VALUES';
export const predictionAcceptedType = 'PREDICTIONS@SET_ACCESPED';
export const predictionSetCurrentLeagueType = 'PREDICTIONS@SET_CURRENT_LEAGUE';
export const predictionClearData = 'PREDICTION@CLEAR_DATA';
export const predictionsTotalPoints = 'PREDICTION@TOTAL_POINTS';

// APPLICATION TYPES
export const applicationIsLoaded = 'APPLICATION@IS_LOADED';
export const currentEnvIsDev = 'APPLICATION@CURRENT_ENVIRONMENT_IS_DEV';
export const setApplicationError = 'APPLICATION@SET_ERROR';
export const setIsMobileMode = 'APPLICATION@SET_IS_MOBILE';

// USERS TYPES
export const setUserName = 'USER@SET_USER_NAME';
export const loadBalance = 'USER@LOAD_BALANCE';
export const loadBonuses = 'USER@LOAD_BONUSES';

// PROFILE TYPES
export const setProfileMenuTab = 'PROFILE@SET_MENU_TAB';
export const setProfileWiningBonusesType = 'PROFILE@SET_WINNING_BONUSES';
export const setProfileBetsType = 'PROFILE@SET_BETS';
export const setSubTab = 'PROFILE@SET_SUB_TAB';
export const setBetsFormatted = 'PROFILE@SET_BETS_FORMATTED';
export const setUserAvatarUploaded = 'PROFILE@SET_AVATAR_UPLOADED';
export const changeUserAvatar = 'PROFILE@SET_AVATAR_CHANGED';

// POPUP
export const popupSetNew = 'POPUP@SET_NEW';
export const popupRemove = 'POPUP@REMOVE';
export const popupClearAll = 'POPUP@CLEAR_ALL';
export const popupShow = 'POPUP@SHOW';
export const popupHide = 'POPUP@HIDE';
