export const domain = `${process.env.API_PROTOCOL}://${process.env.API_DOMAIN}`;

// FREESPINS
export const freespinRegiterPATH = '/wp-json/freespin/v1/register';
export const freespinActivatePATH = '/wp-json/freespin/v1/activation';
export const freespinResetPasswordPATH = '/wp-json/freespin/v1/resetpassword';
export const freespinNewPasswordPATH = '/wp-json/freespin/v1/newpassword';
export const freespinLoginPATH = '/wp-json/jwt-auth/v1/token';
export const freespinBalancePATH = '/wp-json/freespin/v1/balance';
export const freespinGameListPATH = '/wp-json/freespin/v1/gameslist';
export const freespinIframeUrlPATH = '/wp-json/freespin/v1/game';
export const freespinWithdrawCommentPATH = '/wp-json/freespin/v1/withdraw';
export const freespinGetUserAvatarPATH = '/wp-json/freespin/v1/getavatar';
export const freespinUploadUserAvatarPATH = '/wp-json/freespin/v1/uploadavatar';

// BANKING PAGE
export const bankingAuthPATH = '/wp-json/banking-page/v1/Authenticate';
export const bankingGetDepositPATH = '/wp-json/banking-page/v1/DepositModel';
export const bankingMakeDepositPATH = '/wp-json/banking-page/v1/Deposit';
export const bankingMakeDepositQrPATH = '/wp-json/banking-page/v1/DepositQR';
export const bankingGetWithdrawPATH = '/wp-json/banking-page/v1/WithdrawalModel';
export const bankingMakeWithdrawPATH = '/wp-json/banking-page/v1/Withdrawal';
export const userIpFetchUrl = 'https://api.ipify.org?format=json';

// PREDICTION
export const predictionUserInfoPATH = '/wp-json/joomsport_prediction_api/v1/user_info';
export const predictionUserBetsPATH = '/wp-json/joomsport_prediction_api/v1/user_bets';
export const predictionUserBonusesPATH = '/wp-json/joomsport_prediction_api/v1/user_bonuses';
export const predictionMakeABetPATH = '/wp-json/joomsport_prediction_api/v1/bids';
export const predictionRoundsPATH = '/wp-json/joomsport_prediction_api/v1/leagues';

// LOCAL STORAGE
export const FREESPIN_AUTH_TOKEN = 'FREESPIN_AUTH_TOKEN';
export const FREESPIN_USER_NAME = 'FREESPIN_USER_NAME';
export const FREESPIN_USER_EMAIL = 'FREESPIN_USER_EMAIL';
export const BANKING_AUTH_TOKEN = 'BANKING_AUTH_TOKEN';
export const BANKING_USER_NAME = 'BANKING_USER_NAME';
export const NEW_PASSWORD = 'NEW_PASSWORD';
