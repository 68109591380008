import { css } from '@emotion/core';
import styled from '@emotion/styled';

export const breakpoints = {
  xxl: '1920',
  xl: '1700px',
  rt: '1580px',
  lg: '1280px',
  tl: '1024px',
  md: '960px',
  sm: '768px',
  s: '620px',
  xs: '480px',
  xxs: '350px',
};

export const border = {
  borderRadius4: '4px',
  borderRadius8: '8px',
  borderRadius14: '14px',
};

export const area = {
  betweenBlocks: '30px',
  blockPadding: '30px 0',
};

export const gradient = {
  roseBittersweet: 'linear-gradient(0deg, rgb(254, 17, 96) 5%, rgb(255, 124, 100) 85%)',
  purpleBunting: 'linear-gradient(300deg, rgb(33, 30, 151) 4%, rgb(24, 23, 79) 53%)',
  blueAzureBlue:
    'linear-gradient(to right, rgba(98, 176, 239, 1) 0%, rgba(0, 127, 232, 1) 51%, rgba(98, 176, 239, 1) 100%)',
};

export const color = {
  malachite: '#07b507',
  minsk: '#363676',
  martinique: '#2F3551',
  ghost: '#bec3cd',
  waikawaGray: '#5c6a87',
  cello: '#1d295a',
  cloudBurst: '#283361',
  allports: '#0072a3',
  biscay: '#1a2559',
  burgundy: '#a30032',
  blackRussian: '#00011c',
  jacksonsPurple: '#201e94',
  anakiwa: '#90dbfe',
  alabaster: '#f8f8f8',
  luckyPoint: '#17245e',
  supernova: '#ffd10a',
  astronaut: '#24316a',
  razzmatazz: '#F5045F',
  dodgerBlue: '#4780ff',
  violetRed: '#f33b74',
  catskillWhite: '#fbfcfd',
  rose: '#fe1063',
  lightningYellow: '#fec314',
  bunting: '#18174f',
  stratos: '#000337',
  haiti: '#0B0E32',
  blueZodiac: '#0D1244',
  purple: '#32407f',
  lightPurple: '#2d3452',
  middlelightPurple: '#384992',
  blocklightPurple: '#2c3354',
  lightlightPurple: '#4254a2',
  lightBlue: '#28b7fa',
  blue: '#2da5fe',
  activeBlue: '#2da5fe',
  darkPurple: '#141d44',
  black: '#000',
  white: '#fff',
  textColor: '#9da0b1',
  darkWhite: '#cbcbcb',
  scoreColor: '#20294e',
  darkGrey: '#333333',
  lineGrey: '#434a69',
  btnGrey: '#575c74',
  midGrey: '#4d4d4d',
  greyLabel: '#b6bbd3',
  lightGrey: '#e9e9e9',
  moonRaker: '#d4daf8',
  grey: '#abadb8',
  formGrey: '#2f2f2f',
  gradientGreen: '#00aa6c',
  green: '#006800',
  lightGreen: '#00c500',
  lineGreen: '#00b901',
  yellow: '#ffed81',
  yellowLink: '#ffef87',
  chatBtnColor: '#f7bc00',
  pink: '#ff3952',
  darkPink: '#fb0250',
  red: '#F13832',
  transparent: 'rgba(0, 0, 0, 0)',
  transparentWhite2: 'rgba(255,255,255,0.2)',
  transparentWhite4: 'rgba(255,255,255,0.4)',
  transparentWhite5: 'rgba(255,255,255,0.5)',
  transparentWhite05: 'rgba(255,255,255,0.05)',
  mainBackgroundColor: '#00011b',
};

export const sizing = {
  default: '16px',
  normal: '15px',
  text: '14px',
  small: '12px',
  large: '18px',
  heading: '26px',
  bigHeading: '36px',
  huge: '44px',
  width: '90%',
};

export const font = {
  default: ['Kanit', 'Arial', '"Helvetica Neue"', 'sans-serif'].join(),
  heading: ['Kanit', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(),
  lightWeight: 100,
  normalWeight: 400,
  medium: 500,
  mediumWeight: 600,
  headingWeight: 700,
  lineHeight: 1.25,
};

// msg and cssStyles should be ONLY strings.
// Example of usage: styleConsoleLog("I'm console.log", "color: red; font-weight: 800;")
export const styleConsoleLog = (
  msg = '',
  cssStyles = `
    color: red;
    font-weight: 800;
    text-decoration: underline;
    padding: 200px 0;
    font-size: 24px;
  `,
  // eslint-disable-next-line no-console
) => console.log(`%c${msg}`, `${cssStyles}`);

export const button = css`
  display: inline-block;
  background: #575c74;
  border-radius: ${border.borderRadius14};
  color: ${color.white};
  cursor: pointer;
  font-family: ${font.heading};
  font-weight: ${sizing.normalWeight};
  text-align: center;
  text-decoration: none;
  transition-duration: 0.4s;
  -webkit-appearance: none;

  &.color-yellow {
    span {
      color: ${color.lightningYellow};
    }
  }

  &.pink-button {
    background: ${color.pink};
    border-color: ${color.pink};
    background-image: linear-gradient(to left, ${color.pink} 30%, #dd1a33 60%);
    transition-duration: 0.4s;
    box-shadow: 20px 10px 50px -47px rgba(255, 255, 255, 0.5) inset;
    &:hover {
      background-image: linear-gradient(to left, ${color.pink} 20%, #dd1a33 90%);
      box-shadow: 90px 30px 50px -47px rgba(255, 255, 255, 0.5) inset;
    }
  }

  &.blue-button {
    background: #575c74;
    border-color: #575c74;
  }
  &.blue-button:hover,
  &.blue-button:active,
  &.blue-button:focus {
    background: ${color.lightBlue};
    border-color: ${color.lightBlue};
  }
  &.color-blue {
    span {
      color: ${color.lightBlue};
    }
  }
  &.color-blue:hover,
  &.color-blue:active,
  &.color-blue:focus {
    span {
      color: ${color.lightBlue};
    }
  }
  &.no-text {
    padding: 12px;
    border-radius: 50%;
    margin-right: 15px;
    width: 30px;
    height: 30px;
    position: relative;
    svg {
      margin: 0;
      position: absolute;
      left: 20%;
      top: 25%;
    }
  }
  &.withoutBgr {
    padding: 0;
    background: transparent;
  }
  &.withBorder {
    border-radius: ${border.borderRadius4};
    border: solid 1px ${color.lightGrey};
    background: transparent;
  }
`;

export const formStyles = css`
  button,
  .button {
    margin-right: 15px;
    border: none;
    ${button}
  }
  button:disabled,
  .button:disabled,
  .disabled {
    background: ${color.rose};
    cursor: not-allowed;
    &:hover {
      background: ${color.rose};
      cursor: not-allowed;
    }
  }
  .fieldWrap {
    margin-bottom: 5px;
    padding-bottom: 15px;
    position: relative;
  }
  .fieldWrap .error,
  .error {
    position: absolute;
    right: 5%;
    top: 14px;
    font-size: 12px;
    color: ${color.red};
    max-width: 50%;
  }
  label {
    padding: 0 0 5px;
    display: block;
    color: ${color.greyLabel};
    font-size: 14px;
  }
  input[type='date'] {
    background: transparent;
    color: ${color.greyLabel};
    padding-left: 16px;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid ${color.greyLabel};
  }
  input[type='date'] {
    position: relative;
    padding: 10px;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }

  input[type='date']:before {
    background: none;
    display: block;
    font-family: 'FontAwesome', sans-serif;
    content: '';
    /* This is the calendar icon in FontAwesome */
    width: 15px;
    height: 20px;
    position: absolute;
    top: 12px;
    right: 6px;
    color: #999;
  }
  input[type='time'] {
    background: transparent;
    color: ${color.greyLabel};
    padding-left: 16px;
    font-size: 16px;
    border-radius: 8px;
    height: 40px;
    width: 100%;
    border: 1px solid ${color.greyLabel};
  }
  input[type='time']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  input[type='file'],
  input[type='checkbox'] {
    display: none;
  }
  input[type='file']::-webkit-calendar-picker-indicator {
    color: transparent;
    background: none;
    z-index: 1;
  }
  select {
    display: block;
    background: transparent;
    color: ${color.greyLabel};
    padding-left: 16px;
    font-size: 16px;
    border-radius: ${border.borderRadius14};
    outline: none;
    height: 40px;
    width: 100%;
    border: 1px solid ${color.greyLabel};
    option {
      height: 30px;
      display: block;
    }
    option:hover,
    option:active,
    option:focus {
      background-color: red;
      color: ${color.greyLabel};
    }
  }
  option {
    background-color: ${color.blocklightPurple};
    color: ${color.greyLabel};
    padding: 5px;
    height: 100%;
    &:hover,
    &:active,
    &:focus {
      background-color: red;
      color: ${color.greyLabel};
    }
  }

  input[type='text'],
  input[type='email'],
  input[type='number'],
  input[type='password'],
  textarea {
    background: transparent;
    color: ${color.greyLabel};
    padding-left: 16px;
    font-size: 16px;
    border-radius: ${border.borderRadius14};
    height: 40px;
    width: 100%;
    border: 1px solid ${color.greyLabel};
  }
  input:focus,
  textarea:focus {
    outline: none;
    border-color: ${color.midGrey};
  }
  input + span,
  textarea + span {
    color: ${color.yellow};
    font-size: 12px;
    display: block;
    padding: 5px 5px 0;
  }
  textarea {
    min-height: 5rem;
    width: 100%;
  }
  .minimal-custom-radio {
    .radio-wrap {
      margin-bottom: 10px;
    }
    [type='radio']:checked,
    [type='radio']:not(:checked) {
      position: absolute;
      left: -9999px;
    }
    [type='radio']:checked + label,
    [type='radio']:not(:checked) + label {
      position: relative;
      padding-left: 28px;
      cursor: pointer;
      line-height: 20px;
      display: inline-block;
      color: #666;
    }
    [type='radio']:checked + label:before,
    [type='radio']:not(:checked) + label:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      border: 1px solid ${color.formGrey};
      border-radius: 100%;
      background: ${color.black};
    }
    [type='radio']:checked + label:after,
    [type='radio']:not(:checked) + label:after {
      content: '';
      width: 12px;
      height: 12px;
      background: ${color.yellow};
      position: absolute;
      top: 3px;
      left: 3px;
      border-radius: 100%;
      -webkit-transition: all 0.2s ease;
      transition: all 0.2s ease;
    }
    [type='radio']:not(:checked) + label:after {
      opacity: 0;
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    [type='radio']:checked + label:after {
      opacity: 1;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  .minimal-custom-checkbox {
    margin-bottom: 10px;
    input {
      position: absolute;
      opacity: 0;
    }

    input + label {
      position: relative;
      cursor: pointer;
      padding: 0;
    }

    input + label:before {
      content: '';
      margin-right: 10px;
      display: inline-block;
      vertical-align: text-top;
      width: 20px;
      height: 20px;
      background: transparent;
      border: 1px solid ${color.greyLabel};
      border-radius: 2px;
    }

    input:hover + label:before {
      background: ${color.formGrey};
    }

    input:focus + label:before {
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    }

    input:disabled + label {
      color: ${color.white};
      cursor: auto;
    }

    input:disabled + label:before {
      box-shadow: none;
      background: ${color.formGrey};
    }
    input:checked + label:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 9px;
      background: white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
      transform: rotate(45deg);
    }
  }
  .form-bottom {
    .button {
      float: right;
    }
    .notice {
      font-size: 12px;
      color: ${color.transparentWhite2};
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  @media (max-width: ${breakpoints.md}) {
    h2 {
      margin-top: 2rem;
    }
    input[type='text'],
    input[type='email'],
    input[type='password'] {
      width: 100%;
    }
  }
`;

export const reset = css`
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraBGwCYdA.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcrabGwCYdA.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKZ-Go6G5tXcraVGwA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWzVaF5NQ.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWpVaF5NQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr5mOBWnVaE.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  /* thai */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWzVaF5NQ.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWpVaF5NQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Kanit';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/kanit/v7/nKKU-Go6G5tXcr4uPhWnVaE.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  html,
  body,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    text-rendering: optimizeLegibility;
  }
  img {
    display: inline-block;
    max-width: 100%;
    height: auto;
    //object-fit: fill !important;
    margin-right: 10px;
  }
  html,
  body {
    height: 100%;
  }
  body {
    background-color: ${color.mainBackgroundColor};
    color: ${color.white};
    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    margin: 0;
  }
`;

export const wrapper = css`
  margin: 0 auto;
  @media (max-width: ${breakpoints.lg}) {
    h1 {
      font-size: 2rem;
    }
  }
`;

const defaults = {
  duration: '150ms',
  easing: 'linear',
  property: 'all',
};

export const transition = {
  ...defaults,
  default: `${defaults.duration} ${defaults.property} ${defaults.easing}`,
};

export const typography = css`
  h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  h6 {
    color: ${color.white};
    font-family: ${font.heading};
    font-weight: ${font.headingWeight};
    line-height: 1.1;
    margin-bottom: 0.8em;
    em,
    i,
    strong,
    b {
      font-weight: inherit;
    }
  }
  h1,
  .h1 {
    font-size: 2.3rem;
    color: ${color.lightningYellow};
  }
  h2 {
    font-size: 26px;
    margin: 2rem 0 1rem;
    color: ${color.lightningYellow};
  }
  h3,
  .h3 {
    font-size: 2rem;
    margin: 1.8rem 0 1rem;
    color: ${color.lightningYellow};
  }
  h4,
  .h4 h5,
  .h5,
  h6 {
    font-size: 1.5rem;
    margin: 1.5rem 0 1rem;
    + * {
      margin-top: 0.5rem;
    }
  }
  p {
    text-align: justify;
    line-height: 1.5;
  }
  a {
    color: ${color.grey};
    text-decoration: none;
    &:hover,
    &:active,
    &:focus {
      outline: none;
    }
  }
  ul {
    padding: 0 0 10px 20px;
  }

  blockquote {
    color: ${color.yellow};
    font-style: italic;
  }
  .button {
    ${button}
  }
  .screen-reader-text {
    clip: rect(0 0 0 0);
    overflow: hidden;
    position: absolute;
    height: 1px;
    width: 1px;
  }
  .white {
    color: ${color.white};
  }
  .a-center {
    text-align: center;
  }
  .white-stroke {
    border: 1px solid ${color.white};
  }
  .stroked {
    -webkit-text-stroke: 1px ${color.white};
    -webkit-text-fill-color: ${color.transparent};
  }
  table {
    margin: 20px 0;
    width: 100%;
    border: 0;
    border-collapse: collapse;
    td,
    th {
      text-align: left;
      padding: 15px 20px 15px 0;
    }
    th {
      border-bottom: 1px solid ${color.yellow};
      font-weight: ${font.headingWeight};
    }
    td {
      border-bottom: 1px solid ${color.formGrey};
      font-weight: ${font.normalWeight};
    }
  }

  .flex {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .col-sm-6 {
      -webkit-box-flex: 1;
      flex: 1 1 0;
      padding: 10px;
    }
    .col-4 {
      flex-basis: 24%;
      padding: 5px;
    }
    .col-3,
    .col-md-3,
    .col-md-4,
    .col-md-5 {
      flex-basis: 33%;
      padding: 10px;
    }
    .col-2 {
      flex-basis: 49%;
      padding: 10px 20px;
    }
  }

  .iframe-container {
    overflow: hidden;
    padding-top: 56.25%;
    position: relative;
    iframe {
      border: 0;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }

  .clear {
    clear: both;
  }

  @media (max-width: ${breakpoints.md}) {
    h1,
    .h1 {
      font-size: 1.5rem;
    }
    h2,
    .h2 {
      font-size: 1.3rem;
    }
    h3,
    .h3 {
      font-size: 1.2rem;
    }
    h4,
    .h4 {
      font-size: 1.1rem;
    }
    blockquote {
      padding: 25px 7%;
      font-size: 1.2rem;
    }
    p {
      font-size: 14px;
    }
    table {
      td,
      th {
        padding: 10px 15px 10px 0;
      }
    }
    .flex {
      .col-sm-6,
      .col-3,
      .col-2,
      .col-md-3,
      .col-md-4,
      .col-md-5 {
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
        padding: 0;
      }
      .col-4 {
        flex-basis: 49%;
      }
    }
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .flex {
      .col-3 {
        flex-basis: 31%;
      }
    }
    .flex {
      .col-2 {
        flex-basis: 48%;
      }
    }
  }
`;

export const content = css`
  display: flex;
  border-radius: 8px;
  h3 {
    font-size: 1.6rem;
    margin-top: 0;
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    display: inline-block;
    vertical-align: top;
    width: 49%;
    margin-bottom: 1rem;
  }
  li:nth-of-type(odd) {
    padding-right: 20px;
  }
  li:nth-of-type(even) {
    padding-left: 20px;
  }
  .recent-excerpt div {
    margin: 10px 0;
    font-size: ${sizing.small};
  }

  @media (max-width: ${breakpoints.md}) {
    padding: 0;
    h3 {
      font-size: 1.2rem;
    }
    ul {
      margin: 10px 0;
    }
    li {
      width: 100%;
      padding: 10px 0 !important;
    }
  }

  @media (max-width: ${breakpoints.xs}) {
    .recent-excerpt div,
    .recent-excerpt p {
      display: inline;
    }
    .recent-excerpt .button {
      padding: 0;
      background: transparent;
      display: block;
      text-align: left;
      line-height: 1.3;
      color: ${color.blue};
      font-weight: ${sizing.normalWeight};
      font-size: inherit;
      text-decoration: underline;
      &:active,
      &:focus,
      &:hover {
        color: ${color.lightGreen};
        background: none;
      }
    }
  }
`;

export const Slick = css`
  .slick-slider {
    position: relative;
    overflow: hidden;
    border-radius: 0;
    z-index: 0;
    margin-bottom: 40px;
    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    @media (max-width: ${breakpoints.sm}) {
      margin-bottom: 20px;
    }
}

.slick-list {
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border-radius: 0;
}
.slick-list:focus {
  outline: none;
}

.separator {
  border-bottom: 1px solid ${color.transparentWhite2};
}

.slick-list.dragging {
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: content;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.slick-loading .slick-list {
  background: ${color.black};
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 48px;
  height: 48px;
  z-index: 10;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: ${color.white};
  font-weight: bold;
  border: 2px solid ${color.white};
  border-radius: 24px;
  outline: none;
  background: transparent;
  opacity: 0.7;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus,
.slick-next:active,
.slick-next:active
 {
  color: transparent;
  outline: none;
  background: transparent;
  opacity: 1;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: .25;
}


.slick-prev {
  left: 25px;
}
[dir='rtl'] .slick-prev {
  right: 25px;
  left: auto;
}


.slick-next {
  right: 25px;
}
[dir='rtl'] .slick-next {
  right: auto;
  left: 25px;
}


.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  bottom: 39px;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 6px;
  margin: 0 3px;
  padding: 0;
  cursor: pointer;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 35px;
  height: 6px;
  padding: 0;
  margin-right: 10px;
  cursor: pointer;
  color: transparent;
  border-radius: 50rem;
  border: 0;
  outline: none;
  opacity: 0.2;
  background-color: ${color.white};
}

.slick-dots li button:hover,
.slick-dots li button:focus,
.slick-dots li button:active {
  outline: none;
  opacity: 1;
  color: ${color.white};
}
.slick-dots li.slick-active button {
  opacity: 1;
  color: ${color.white};
  background-color: ${color.lightningYellow};
}
}

@media (max-width: ${breakpoints.sm}) {
  .slick-dots {
    display: block;
    bottom: 5px;
    left: 0;
    text-align: center;
  }
  .slick-dots li, .slick-dots li button, .slick-dots li button:before {
   
  }
}
`;

export const title = css`
  h1,
  .top-title {
    position: absolute;
    left: 140px;
    top: 36%;
    z-index: 1;
    //text-shadow:
    //0 6px 8px #003b6b, 0 0 14px rgba(0, 192, 255, 0.49), 0 -1px 1px rgba(0, 79, 123, 0.5),
    //  0 -1px 4px #00a8db;
    //-webkit-text-stroke: 1.4px #00c5ff;
    font-size: 50.4px;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: ${color.lightningYellow};
    img {
      position: absolute;
      left: -70px;
      top: 10px;
      transition-duration: 0.4s;
      border-radius: ${border.borderRadius4};
    }
    @media (max-width: ${breakpoints.sm}) {
      left: 20%;
      top: 35%;
      font-size: 24px;
      img {
        left: -60px;
        top: 3%;
      }
    }
  }
`;

export const tabs = css`
  display: flex;
  width: min-content;
  @keyframes movetabs {
    from {
      left: -500px;
    }
    to {
      left: 0;
    }
  }
  li {
    padding: 0;
    margin-right: 6px;
    width: auto;
    a,
    span {
      height: 43px;
      width: 100%;
      display: block;
      padding: 10px 20px;
      text-transform: uppercase;
      text-overflow: ellipsis;
      font-size: 16px;
      font-weight: bold;
      color: ${color.greyLabel};
      background-color: #111731;
      white-space: nowrap;
      border-radius: 8px 8px 0 0;
      cursor: pointer;
      transition-duration: 0.4s;
      &.active,
      &:hover {
        color: ${color.white};
        background-color: ${color.darkPurple};
      }
    }
  }
  @media (max-width: ${breakpoints.sm}) {
    top: 0;
  }
`;

export const ContentStyles = css`
  .gatsby-image-wrapper {
    margin: 20px auto;
  }
  p {
    margin: 10px 0;
  }
  a {
    color: ${color.yellow};
  }
  ol {
    list-style: none;
    counter-reset: li;
    margin: 15px 0;
    padding: 0;
    li {
      counter-increment: li;
      margin-left: 20px;
      &::before {
        content: counter(li);
        display: inline-block;
        width: 1em;
        margin-left: -1.5em;
        margin-right: 0.5em;
        text-align: right;
        direction: rtl;
        color: ${color.yellow};
      }
    }
  }
  ul {
    list-style: none;
    font-weight: 600;
    letter-spacing: 0.75px;
    line-height: 160%;
    font-size: 16px;
    li {
      position: relative;
      padding-left: 35px;
      &::before {
        content: '→';
        position: absolute;
        top: 0;
        left: 0;
        width: 1em;
        color: ${color.yellow};
        font-family: Verdana, Tahoma, sans-serif;
        font-size: 20px;
      }
    }
  }
`;

export const hideScroll = {
  '&::-webkit-scrollbar': {
    width: '0px',
    height: '0px',
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'none',
  },
  '&::-webkit-scrollbar-track': {
    display: 'none',
  },
};

export const socialIcon = {
  width: '32px',
  height: '32px',
  backgroundColor: 'rgba(255,255,255,0.15)',
  borderRadius: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: '0.5s',
  margin: '0',

  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.25)',
  },

  '& > svg': {
    position: 'absolute',
  },
};

export const SocialIconWrapper = styled('a')`
  ${socialIcon};
  float: left;
`;

export const resetButton = {
  border: 'none',
  background: 'none',
  outline: 'none',
  padding: 0,
  margin: 0,
  cursor: 'pointer',
  textDecoration: 'none',
};

export const resetList = {
  listStyle: 'none',
  padding: 0,
  margin: 0,

  '& > li': {
    padding: 0,
    margin: 0,
  },
};

export const linesLimit = (lines = 1) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': `${lines}`,
  '-webkit-box-orient': 'vertical',
});

export const flex = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export const textEllipsis = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const formsAutoFillingStyles = css`
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & textarea:-webkit-autofill:active,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus,
  & select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px ${color.blocklightPurple} inset !important;
    -webkit-text-fill-color: ${color.greyLabel} !important;
    border: 1px solid ${color.greyLabel} !important;
    caret-color: ${color.greyLabel} !important;
    background-color: transparent !important;
  }

  /* Safari feature */
  & input,
  & input:before,
  & input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }
`;

export const formsAutoFillingStylesBlack = css`
  & input:-webkit-autofill,
  & input:-webkit-autofill:hover,
  & input:-webkit-autofill:focus,
  & input:-webkit-autofill:active,
  & textarea:-webkit-autofill,
  & textarea:-webkit-autofill:hover,
  & textarea:-webkit-autofill:focus,
  & textarea:-webkit-autofill:active,
  & select:-webkit-autofill,
  & select:-webkit-autofill:hover,
  & select:-webkit-autofill:focus,
  & select:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 100px #01011b inset !important;
    -webkit-text-fill-color: ${color.greyLabel} !important;
    border: 1px solid ${color.greyLabel} !important;
    caret-color: ${color.greyLabel} !important;
    background-color: transparent !important;
  }
`;

export const redButton = css`
  width: 258px;
  height: 44px;
  border-radius: ${border.borderRadius14};
  background: ${color.rose};
  background: ${gradient.roseBittersweet};

  font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${color.catskillWhite};

  &:hover {
    background: #ff497f;
  }

  &:active {
    background: #a70034;
  }
`;

export default {
  button,
  color,
  sizing,
  font,
  reset,
  transition,
  typography,
  border,
};
