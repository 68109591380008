export const breakpoints = {
  xxl: '1920',
  xl: '1700',
  rt: '1580',
  lg: '1280',
  tl: '1024',
  md: '960',
  sm: '768',
  xs: '480',
  xxs: '350',
};

export const mq = (bp) => {
  const bpArray = Object.keys(breakpoints)?.map((key) => [key, breakpoints[key]]);

  const [result] = bpArray.reduce((prev, [name, size]) => {
    if (bp === name) return [...prev, `@media (max-width: ${size}px)`];
    return prev;
  }, []);

  return result;
};

export const reversesMq = (bp) => {
  const bpArray = Object.keys(breakpoints)?.map((key) => [key, breakpoints[key]]);

  const [result] = bpArray.reduce((prev, [name, size]) => {
    if (bp === name) return [...prev, `@media (min-width: ${size}px)`];
    return prev;
  }, []);

  return result;
};

export const spreadProps = (props = {}) => ({
  // eslint-disable-next-line
  ...Object.keys(props).reduce((prev, key) => (prev[key] = props[key]) && prev, []),
});
