import React from 'react';
import { breakpoints as bp } from './mediaQuery';

export const validateLink = (link) => (link.charAt(0) === '/' ? link : `/${link}`);

export const isNan = (value) => Number.isNaN(Number(value));

export const isMobile = (mq = bp.md) => (!isNan(mq) && window ? window.outerWidth < Number(mq) : false);

export const isDevDomain = () =>
  window.location.host.includes('localhost:8000') || window.location.host.includes('dev.');

export const debounce = (fn, ms = 0) => {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const asyncPipe = (...fns) => (x) => fns.reduce(async (res, fn) => fn(await res), x);

export const linksInterpolate = (literals, ...expressions) => {
  // eslint-disable-next-line quotes
  let string = ``;
  // eslint-disable-next-line no-restricted-syntax
  for (const [i, val] of expressions.entries()) {
    if (!~~i && val !== '/') string = '/';
    string += literals[i] + val;
  }
  string += literals[literals.length - 1];
  if (string.charAt(-1) !== '/') string += '/';
  return string.replace('//', '/');
};

export const categoryLinksInterpolate = (string = '') => {
  const val = string.replace(/category/i, '');
  return linksInterpolate`${val}`;
};

// REFACTOR
// Need to be created folder 'translation' or just another file with this name in utils and there are with DICTIONARY'S
// (NOT SWITCH) creating all translates. In future it's can help with multi language
export const convertMenuNames = (slug) => {
  switch (slug) {
    case 'sports':
      return 'กีฬา';
    case 'virtual-sports':
      return 'กีฬาเสมือนจริง';
    case 'games-slots':
      return 'เกมสล็อต';
    case 'live-casino':
      return 'คาสิโน-สด';
    case 'racing':
      return 'ดวลความเร็ว';
    case 'lottery':
      return 'หวย';
    default:
      return slug;
  }
};

export const getFile = (featured_media, key = 'fluid') =>
  featured_media &&
  featured_media.localFile &&
  featured_media.localFile.childImageSharp &&
  featured_media.localFile.childImageSharp[key];

export const getLocalFile = (file, key = 'fluid') => file.childImageSharp && file.childImageSharp[key];

export const makeKey = (string) => string.toLowerCase().replace(' ', '-');

export const intersectionsOfSymbols = (arr = [], string = '') => arr.filter((symbol) => string.includes(symbol)).length;

// FORMS
export const isValidField = (key, errors, touched, validating = false) =>
  (errors[key] && touched[key] && validating ? errors[key] : false);
export const calcPassScore = (pass = '') => {
  let score = 0;

  const m_strUpperCase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const m_strLowerCase = 'abcdefghijklmnopqrstuvwxyz';
  const m_strNumber = '0123456789';
  const m_strCharacters = '!@#$%^&*?_~';
  const passLength = pass.length;

  // Length score
  if (passLength < 5) {
    score += 5;
  } else if (passLength >= 5 && passLength <= 8) {
    score += 10;
  } else if (passLength > 8) score += 20;

  // Upper Case score
  const upperCaseCount = intersectionsOfSymbols(pass.split(''), m_strUpperCase);
  if (upperCaseCount < 3 && upperCaseCount > 0) {
    score += 5;
  } else if (upperCaseCount >= 3 && upperCaseCount <= 4) {
    score += 10;
  } else if (upperCaseCount > 4) score += 20;

  // Lower Case Score
  const lowerCaseCount = intersectionsOfSymbols(pass.split(''), m_strLowerCase);
  if (lowerCaseCount < 3 && lowerCaseCount > 0) {
    score += 5;
  } else if (lowerCaseCount >= 3 && lowerCaseCount <= 4) {
    score += 10;
  } else if (lowerCaseCount > 4) score += 20;

  // Numbers Score
  const numbersCount = intersectionsOfSymbols(pass.split(''), m_strNumber);
  if (numbersCount < 3 && numbersCount > 0) {
    score += 5;
  } else if (numbersCount >= 3 && numbersCount <= 4) {
    score += 10;
  } else if (numbersCount > 4) score += 20;

  // Symbols Score
  const symbolsCount = intersectionsOfSymbols(pass.split(''), m_strCharacters);
  if (symbolsCount < 3 && symbolsCount > 0) {
    score += 5;
  } else if (symbolsCount >= 3 && symbolsCount <= 4) {
    score += 10;
  } else if (symbolsCount > 4) score += 20;

  return score;
};

// LOCALSTORAGE FEATURES
export const hasStorage = () => window && true;
export const setStorageItem = (key, value) => hasStorage() && window.localStorage.setItem(key, value);
export const getStorageItem = (key) => hasStorage() && window.localStorage.getItem(key);
export const clearStorageItem = (key) => hasStorage() && window.localStorage.removeItem(key);

// DATE
export const addZero = (number) => (+number < 10 ? `0${number}` : number);
export const buildDate = (date, separator) =>
  `${addZero(date.getDate())}${separator}${addZero(date.getMonth())}${separator}${date.getFullYear()}`;
export const buildTime = (time, separator) => `${addZero(time.getHours())}${separator}${addZero(time.getMinutes())}`;
export const getMonthsArr = (short = null) =>
  (short
    ? ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    : [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]);
export const winningBonusesDate = (string) => {
  const date = new Date(string);
  return `${addZero(date.getDate())}/${addZero(date.getMonth() + 1)}/${date.getFullYear()}`;
};
export const timeDiff = (dateTime) => {
  if (!dateTime || dateTime === '') return false;

  const dateTimeObj = new Date(dateTime);
  const HOUR = 1000 * 60 * 60;
  const diffMs = dateTimeObj.getTime() - (Date.now() - HOUR * 12);
  const seconds = Math.floor((diffMs / 1000) % 60);
  const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
  const hours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);

  if (diffMs < 0) return false;
  return {
    hours,
    minutes,
    seconds,
  };
};
