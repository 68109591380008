import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import dictionary from '../utils/dictionary';

const initialState = {
  predictions: {
    title: '',
    id: '',
    matchBets: {},
    predictionData: [],
    leaderData: [],
    historyData: [],
    isRoundsLoaded: false,
    isLeadersLoaded: false,
    isHistoryLoaded: false,
    betsErrors: {},
    onSubmit: false,
    onSaved: true,
    accepted: false,
    currentLeague: {},
    totalPoints: 0,
  },
  application: {
    isLoaded: false,
    currentEnvIsDev: false,
    error: null,
    isMobile: false,
  },
  user: {
    userName: '',
    balance: null,
    bonuses: null,
  },
  profile: {
    menus: {
      bets: [dictionary.bets_allBets, dictionary.bets_alreadyPlayed, dictionary.bets_openBets],
      winning_bonuses: [],
      account_settings: [dictionary.as_security],
    },
    activeTab: '',
    winning_bonuses: null,
    bets: null,
    activeSubTab: null,
    betsFormatted: {},
    userAvatar: '',
    setAvatar: false,
  },
  popup: {},
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      { children }
    </StoreContext.Provider>
  );
};

export const StoreContext = createContext(initialState);
export default Store;
