import {
  freespinActivatePATH, freespinBalancePATH,
  freespinGameListPATH, freespinGetUserAvatarPATH, freespinIframeUrlPATH,
  freespinLoginPATH, freespinNewPasswordPATH,
  freespinRegiterPATH, freespinResetPasswordPATH, freespinUploadUserAvatarPATH,
  freespinWithdrawCommentPATH,
} from './config';
import {
  bearerRequest,
  clearToken,
  clearUserEmail,
  clearUserName, getNewPass,
  getUserEmail,
  getUserName,
  request,
} from './index';

export const freespinLogin = (username, password) => request({
  path: freespinLoginPATH,
  method: 'POST',
  body: {
    username,
    password,
  },
});

export const freespinRegiter = ({ username, email, phone, line, password }) => request({
  path: freespinRegiterPATH,
  method: 'POST',
  body: {
    username,
    email,
    phone,
    line,
    password,
    register_type: { free_speen: '1' },
  },
});

export const freespinActivation = (email, code) => request({
  path: freespinActivatePATH,
  method: 'POST',
  body: {
    email,
    code,
  },
});

export const freespinResetPassword = () => bearerRequest({
  path: freespinResetPasswordPATH,
  method: 'POST',
  body: {
    username: getUserName(),
    email: getUserEmail(),
  },
});

export const freespinNewPassword = (code) => bearerRequest({
  path: freespinNewPasswordPATH,
  method: 'POST',
  body: {
    username: getUserName(),
    password: getNewPass(),
    code,
  },
});

export const freespinBalance = () => bearerRequest({
  path: freespinBalancePATH,
});

export const freespinGameList = () => bearerRequest({
  path: freespinGameListPATH,
});

export const freespinIframeUrl = (code) => bearerRequest({
  path: freespinIframeUrlPATH,
  method: 'POST',
  body: {
    code,
  },
});

export const freespinWithdrawComment = ({ username, comment, rating, emoji }) => bearerRequest({
  path: freespinWithdrawCommentPATH,
  method: 'POST',
  body: {
    username,
    comment,
    rating,
    emoji,
  },
});

export const freespinLogout = (setUser, toHome = false) => {
  clearToken();
  clearUserName();
  clearUserEmail();
  if (window && toHome) window.location.href = '/';
  if (setUser) setUser();
};

export const freespinGetAvatar = () => bearerRequest({
  path: freespinGetUserAvatarPATH,
  method: 'POST',
});

export const freespinUploadAvatar = ({ image }) => bearerRequest({
  path: freespinUploadUserAvatarPATH,
  method: 'POST',
  body: {
    img: image,
  },
});
