import {
  predictionUserInfoPATH,
  predictionUserBetsPATH,
  predictionUserBonusesPATH,
  predictionMakeABetPATH,
  predictionRoundsPATH,
} from './config';
import { bearerRequest, isToken, request } from './index';

export const predictionGetUserInfo = async () => await bearerRequest({
  path: predictionUserInfoPATH,
  method: 'GET',
});

export const predictionUserBets = async () => await bearerRequest({
  path: predictionUserBetsPATH,
});

export const predictionUserBonuses = async () => await bearerRequest({
  path: predictionUserBonusesPATH,
});

export const predictionMakeBet = async (id, matchBets) => await bearerRequest({
  path: `${predictionMakeABetPATH}/${id}`,
  method: 'POST',
  body: matchBets,
});

export const predictionRounds = async () => {
  if (!isToken()) return request({ path: predictionRoundsPATH });
  return bearerRequest({ path: predictionRoundsPATH });
};
